import {
  type DropdownOption,
  Input,
  Button,
  Dropdown,
} from '@hummingbird/shared';
import { Controller, useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './GrantSiteAccessForm.styled';

const commonRoles = ['KISKI-PUBLISHER', 'KISKI-VIEWER', 'PORTAL-VIEWER'];

export interface AccessFormData {
  email: string;
  role: string;
}

interface Props {
  isSite?: boolean;
  isEditMode: boolean;
  role: string;
  onCancel: () => void;
  onSubmit: (formData: AccessFormData) => void;
}

const GrantAccessForm = ({
  isSite = true,
  isEditMode,
  role,
  onCancel,
  onSubmit,
}: Props) => {
  const { register, handleSubmit, control } = useForm<AccessFormData>({
    defaultValues: {
      email: '',
      role,
    },
  });

  const roles = isSite ? commonRoles : [...commonRoles, 'FUND-VIEWER'];

  const dropdownOptions: DropdownOption[] =
    roles.map(r => ({ label: r, value: r })) || [];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('email')}
        isErrorInitiallyHidden
        label="Email"
        placeholder="Enter email"
      />
      {isEditMode && (
        <Controller
          control={control}
          name="role"
          render={({ field: { onChange, value } }) => (
            <Dropdown
              inputProps={{
                label: 'Role',
                placeholder: 'Enter role',
                isErrorInitiallyHidden: true,
              }}
              options={dropdownOptions}
              value={value}
              onChange={onChange}
            />
          )}
        />
      )}
      <ButtonsContainer>
        <Button size="big" variant="outlined-primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="big" type="submit">
          Apply
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default GrantAccessForm;
