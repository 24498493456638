import {
  Button,
  Eye,
  EyeDisabled,
  IconButton,
  Modal,
} from '@hummingbird/shared';
import { useState } from 'react';

import { ButtonContainer } from './VisibilityButton.styled';

import useUpdateFund from 'services/api/useUpdateFund';

interface Props {
  fund: Fund;
  siteId: string;
}

const VisibilityButton = ({ fund, siteId }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { mutate: updateFund } = useUpdateFund();

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChangeSiteVisibility = () => {
    if (!fund) {
      return;
    }

    const { options, name, id } = fund;

    updateFund({
      id,
      siteId,
      payload: {
        name,
        options: {
          ...options,
          hidden: !options.hidden,
        },
      },
    });

    closeModal();
  };

  const isHidden = fund.options.hidden;

  const message = isHidden
    ? 'Users will be able to see this Fund'
    : 'Users will not be able to see this Fund anymore';

  return (
    <>
      <IconButton
        icon={isHidden ? EyeDisabled : Eye}
        label="visibility"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={!!isModalOpen}
        title={`Are you sure you want to make the Fund ${
          isHidden ? 'visible' : 'invisible'
        }?`}
        onClose={closeModal}>
        {message}
        <ButtonContainer>
          <Button size="big" variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handleChangeSiteVisibility}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default VisibilityButton;
