import {
  Button,
  Eye,
  EyeDisabled,
  IconButton,
  Modal,
} from '@hummingbird/shared';
import { useState } from 'react';

import { ButtonContainer } from './VisibilityButton.styled';

import useUpdateSite from 'services/api/useUpdateSite';

interface Props {
  site: Site;
}

const VisibilityButton = ({ site }: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { mutate: updateSite } = useUpdateSite();

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleChangeSiteVisibility = () => {
    if (!site) {
      return;
    }

    const { hidden, id } = site;

    updateSite({
      id,
      payload: {
        hidden: !hidden,
        name: site.portal_name,
      },
    });

    closeModal();
  };

  const isHidden = !!site?.hidden;

  const message = isHidden
    ? 'Users will be able to see this Site'
    : 'Users will not be able to see this Site anymore';

  return (
    <>
      <IconButton
        icon={isHidden ? EyeDisabled : Eye}
        label="visibility"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={!!isModalOpen}
        title={`Are you sure you want to make the Site ${
          isHidden ? 'visible' : 'invisible'
        }?`}
        onClose={closeModal}>
        {message}
        <ButtonContainer>
          <Button size="big" variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handleChangeSiteVisibility}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default VisibilityButton;
