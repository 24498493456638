import { Input, Button, Dropdown, DropdownOption } from '@hummingbird/shared';
import { Controller, useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './ModifyAccessForm.styled';

const roles = ['KISKI-PUBLISHER', 'KISKI-VIEWER', 'PORTAL-VIEWER'];

interface FormData {
  email: string;
  role: string;
}

interface Props {
  user: User;
  onCancel: () => void;
  onSubmit: (formData: FormData) => void;
}

const ModifyAccessForm = ({ user, onCancel, onSubmit }: Props) => {
  const { control, register, handleSubmit } = useForm<FormData>({
    defaultValues: {
      email: user.email,
      role:
        user.site_role === 'Kiski Viewer' ? 'KISKI-VIEWER' : 'KISKI-PUBLISHER',
    },
  });

  const dropdownOptions: DropdownOption[] =
    roles.map(role => ({ label: role, value: role })) || [];

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('email')}
        isDisabled
        isErrorInitiallyHidden
        label="Email"
      />
      <Controller
        control={control}
        name="role"
        render={({ field: { onChange, value } }) => (
          <Dropdown
            inputProps={{
              label: 'Role',
              placeholder: 'Enter role',
              isErrorInitiallyHidden: true,
            }}
            options={dropdownOptions}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <ButtonsContainer>
        <Button size="big" variant="outlined-primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="big" type="submit">
          Apply
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default ModifyAccessForm;
