import { Checkbox, Button, Input } from '@hummingbird/shared';
import { useForm } from 'react-hook-form';

import { ButtonsContainer, Form } from './CreateSiteForm.styled';

import { NAME_MAX_LENGTH } from 'pages/PortalsAdmin/constants';

export interface CreateSiteFormData {
  name: string;
  domain: string;
  visible: boolean;
  externalId: string;
}

interface Props {
  onCancel: () => void;
  onSubmit: (formData: CreateSiteFormData) => void;
}

const CreateSiteForm = ({ onCancel, onSubmit }: Props) => {
  const { formState, register, handleSubmit } = useForm<CreateSiteFormData>({
    defaultValues: {
      name: '',
      domain: '',
      visible: false,
      externalId: '',
    },
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Input
        {...register('name', {
          required: { value: true, message: 'This field is required' },
          maxLength: {
            value: NAME_MAX_LENGTH,
            message: 'Name should be less than 50 characters long',
          },
        })}
        isErrorInitiallyHidden
        error={formState.errors.name?.message}
        label="Site name"
        placeholder="Enter site name"
      />
      <Input
        {...register('domain')}
        isErrorInitiallyHidden
        label="Domain"
        placeholder=".portal.kiski.com"
      />
      <Checkbox {...register('visible')} label="Visible" />
      <ButtonsContainer>
        <Button size="big" variant="outlined-primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="big" type="submit">
          Submit
        </Button>
      </ButtonsContainer>
    </Form>
  );
};

export default CreateSiteForm;
