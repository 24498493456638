import { Bin, Button, IconButton, Modal } from '@hummingbird/shared';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ButtonContainer } from './DeleteFolderButton.styled';

import useDeleteFolder from 'services/api/useDeleteFolder';

interface Props {
  folder: Folder;
}

const DeleteFolderButton = ({ folder }: Props) => {
  const { siteId = '', fundId = '' } = useParams();
  const { mutate: deleteFolder } = useDeleteFolder(fundId);

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnConfirm = () => {
    deleteFolder({ siteId, fundId, folderId: folder.id });
    closeModal();
  };

  return (
    <>
      <IconButton
        icon={Bin}
        label="Delete"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        title={`Are you sure you want to delete "${folder.name}" folder?`}
        onClose={closeModal}>
        Folder and all of its content will be deleted
        <ButtonContainer>
          <Button size="big" variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handleOnConfirm}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default DeleteFolderButton;
