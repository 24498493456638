import { Bin, Button, IconButton, Modal } from '@hummingbird/shared';
import { useState } from 'react';

import { ButtonContainer } from './DeleteSiteButton.styled';

import useDeleteSite from 'services/api/useDeleteSite';

interface Props {
  site: Site;
}

const DeleteSiteButton = ({ site }: Props) => {
  const { mutate: deleteSite } = useDeleteSite();

  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOnConfirm = () => {
    deleteSite({ id: site.id });
    closeModal();
  };

  return (
    <>
      <IconButton
        icon={Bin}
        label="Delete"
        variant="simple"
        onClick={() => setModalOpen(true)}
      />
      <Modal
        isOpen={isModalOpen}
        title={`Are you sure you want to delete "${site.portal_name}" site?`}
        onClose={closeModal}>
        Site and all of its content will be deleted
        <ButtonContainer>
          <Button size="big" variant="outlined-primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button size="big" onClick={handleOnConfirm}>
            Yes, I am sure
          </Button>
        </ButtonContainer>
      </Modal>
    </>
  );
};

export default DeleteSiteButton;
